import { render, staticRenderFns } from "./shoppingCart.vue?vue&type=template&id=b9c38be8&scoped=true&"
import script from "./shoppingCart.vue?vue&type=script&lang=js&"
export * from "./shoppingCart.vue?vue&type=script&lang=js&"
import style0 from "./shoppingCart.vue?vue&type=style&index=0&id=b9c38be8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9c38be8",
  null
  
)

export default component.exports