<template>
	<div style="padding-top:50px;background: #f5f5f5;">
		<van-nav-bar :title="'购物车'+(proxiedUser.userName?('('+proxiedUser.userName+')'):'')" left-text="" fixed></van-nav-bar>
		<div class="goodsList" v-if="dataList.length" :style="{height: containerHeight}">
			<div class="shopGoodsContainer" v-if="dataList.length" v-for="(shop,shopIndex) in dataList">
				<template v-if="shop.goodsList&&shop.goodsList.length>0">
					<div class="shopTitle" style="margin-bottom: 6px;padding: 8px 0;">
						<van-checkbox v-model="shop.isChecked" checked-color="#ff4040"
							@click.stop="checkShopGoods(shopIndex)" />
						<span @click="toShopDetail(shop.shopId)">
							<van-icon name="send-gift" />{{shop.shopName}}
							<van-icon name="arrow" />
						</span>
					</div>
					<ul>
						<li v-for="(item,goodsIndex) in shop.goodsList" :key="goodsIndex">
							<van-swipe-cell>
								<div class="listDetails">
									<van-checkbox :value="item.goodsId" v-model="item.isChecked" checked-color="#ff4040"
										@click="checkGoods(shopIndex,goodsIndex)"></van-checkbox>
									<div class="">
										<img :src="getImgUrl(item.goodsLogo)" style="width: 90px; height: 90px;border-radius: 5px; border: 1px solid #ededed; box-shadow: 1px 2px 2px #ededed;"
											@click="goToGoodsInfo(item.goodsId,item.instanceId)">
									</div>
									<div style="flex-grow: 1;padding:0 15px;">
										<div class="goodsTitle">
											<div class="van-multi-ellipsis--l2">{{item.goodsRemark}}</div>
											<div class="van-multi-ellipsis--l2"
												style="font-size: 13px;color:#999;margin:5px 0;">
												{{getInstanceDesc(item,true)}}
											</div>
										</div>
										<!-- <div v-if="item.goodsPrice - item.addPrice < 0" class="goodsMinus">
                                            比加入时少了{{item.addPrice- item.goodsPrice}}元
                                        </div> -->

										<div class="flex-between" style="font-size: 13px;color:#999;">
											<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->
											<!-- <span style="position: relative;text-decoration: line-through;">¥ {{qy.formatMoney(goods.goodsPrice)}}</span> -->
											<span style="font-size:12px;color:red;"><span
													style="font-size:12px;color:red;">¥ </span>
												{{qy.formatMoney(item.wholesalePrice,"面议")}}</span>
											<span style="">库存：{{item.stockNum}}</span>
										</div>
										<div class="flex-between"
											style="margin:6px 0;padding-right: 6px;;justify-content: flex-start;">
											<div class="flex-between" style="color:#333;">
												<div style="padding:5px;background-color: #f2f3f5;"><van-icon
														name="minus"
														@click.stop="addCartNum(item,-1);updateTotalPrice();" />
												</div>
												<span
													style="padding:5px ;min-width:20px;text-align:center;;margin: 0 2px;;;background-color: #f2f3f5;">{{item.cartNum}}</span>
												<div style="padding:5px;background-color: #f2f3f5;"><van-icon
														name="plus"
														@click.stop="addCartNum(item,1);updateTotalPrice();" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<template #right>
									<van-button square type="danger" text="删除" style="height: 100%"
										@click="delGoods(shopIndex,goodsIndex)" />
								</template>
							</van-swipe-cell>
						</li>
					</ul>
				</template>
			</div>
			<div style="height: 120px;"></div>
		</div>
		<van-submit-bar :price="totalPrice*100" button-text="结算" @submit="onSubmit" style="bottom:50px;color: red;">
			<van-checkbox v-model="isCheckedAll" :disabled="disabled" @click="checkAllGoods(-1)"
				checked-color="#ff4040" style="position: relative;;top: 2px;">全选</van-checkbox>
		</van-submit-bar>
		<bottomTabNav :index="3"></bottomTabNav>
	</div>
</template>
<script type="text/javascript">
	import bottomTabNav from "@/components/bottomTabNav";
	import request from '@/utils/request';
	export default {
		// name: "shopingCart",
		components: {
			bottomTabNav
		},
		data() {
			return {
				containerHeight: 0,
				isCheckedAll: false,
				totalPrice: 0,
				dataList: [],
				total: 0,
				proxiedUser:{},
				disabled: false
			}
		},
		computed: {
		},
		mounted() {
			this.containerHeight = document.documentElement.clientHeight - 150 + "px";
		},
		created() {
			let isLogin = this.checkLogin(true);
			if (!isLogin) {
				return;
			}
			this.proxiedUser=this.getProxiedUser();
			request.post("/api/shopCart/list").then(rs => {
				let obj = {}
				rs.data.forEach(item => {
					item.goodsDesc = item.goodsRemark;
					item.cartNum = item.goodsNum;
					item.instanceId = item.goodsInstanceId;
					if (!obj[item.shopId]) {
						obj[item.shopId] = {
							shopName: item.shopName,
							shopId: item.shopId,
							goodsList: []
						}
					}
					// console.log(JSON.stringify(item))
					obj[item.shopId].goodsList.push(item);
				});
				let arr = []
				for (let k in obj) {
					arr.push(obj[k])
				}
				this.dataList = arr;
			});
			this.hideShare();
		},
		methods: {
			toShopDetail(shopId) {
				this.$router.push({
					path: "/shop",
					query: {
						id: shopId
					}
				})
			},
			goToGoodsInfo(goodsId,instanceId) {
				this.$router.push({
					path: "/goodsInfo",
					query: {
						goodsId: goodsId,
						instanceId:instanceId
					}
				})
			},
			onSubmit() {
				let checkedGoods = [];
				let shopObj = {};
				for (let i = 0; i < this.dataList.length; i++) {
					for (let j = 0; j < this.dataList[i].goodsList.length; j++) {
						let goods = this.dataList[i].goodsList[j];
						if (goods.isChecked && goods.cartNum > 0) {
							goods.goodsNum = goods.cartNum;
							checkedGoods.push(goods);
							shopObj[goods.shopId] = 1;
						}
					}
				}
				let shopNum = 0;
				for (var k in shopObj) {
					shopNum++;
				}
				/* if (shopNum > 1) {
					this.Toast("目前只支持一个店铺商品下单");
					return;
				} */
				if (checkedGoods.length > 0) {
					this.storage.set("goodsList", JSON.stringify(checkedGoods));
					this.$router.push("/orderAdd")
				}
			},

			checkGoods(shopIndex, goodsIndex) {
				let shop = this.dataList[shopIndex],
					shopChecked = true;
				for (let i = 0; i < shop.goodsList.length; i++) {
					if (!shop.goodsList[i].isChecked) {
						shopChecked = false;
						break;
					}
				}
				shop.isChecked = shopChecked;
				this.updateTotalPrice();

			},
			updateGoodsNum(shopIndex, goodsIndex, val) {
				let goods = this.dataList[shopIndex].goodsList[goodsIndex];
				goods.goodsNum = goods.goodsNum + val;
				request.post("/api/shopCart/edit", {
					goodsInstanceId: goods.goodsInstanceId,
					goodsNum: val
				})
			},
			checkAllGoods(shopIndex) {
				for (let i = 0; i < this.dataList.length; i++) {
					this.dataList[i].isChecked = this.isCheckedAll;
					this.checkShopGoods(i);
				}
				this.updateTotalPrice();
			},
			checkShopGoods(shopIndex, byAll) {
				let shop = this.dataList[shopIndex];

				shop.goodsList.forEach(item => {
					item.isChecked = shop.isChecked;
				})
				if (!byAll) {
					this.updateTotalPrice();
				}
			},
			updateTotalPrice() {
				let totalPrice = 0;
				for (let i = 0; i < this.dataList.length; i++) {

					for (let j = 0; j < this.dataList[i].goodsList.length; j++) {
						let goods = this.dataList[i].goodsList[j];
						if (goods.isChecked) {
							totalPrice += goods.wholesalePrice * goods.cartNum;
						}

					}
				}
				this.totalPrice = totalPrice;
			},
			onGoodsNumChange(value, {
				name: goodsInstanceId
			}) {

				this.updateTotalPrice();
				request.post("/api/shopCart/edit", {
					goodsInstanceId: goodsInstanceId,
					goodsNum: value,
					isPlus: false
				})
			},
			delGoods(shopIndex, goodsIndex) {
				let goodsList = this.dataList[shopIndex].goodsList,
					goods = goodsList[goodsIndex],
					_this = this;
				this.Dialog.confirm({
					message: '确认要删除该商品吗',
				}).then(() => {
					request.post("/api/shopCart/remove", {
						goodsInstanceId: goods.goodsInstanceId
					}).then(rs => {
						if (rs.success) {
							goodsList.shift(goodsList, goodsIndex, 1);
							if (goods.isChecked) {
								_this.updateTotalPrice();
							}
						}
					})

				})

			},
			goodsParamDesc(instance) {
				let desc = "";
				if (instance) {
					let arr = [];
					for (let i = 1, k; i <= 4; i++) {
						k = "param" + i + "Val";
						if (instance[k]) {
							arr.push(instance[k])
						}
					}
					desc = arr.join();
				}
				return desc;
			}

		}
	}
</script>
<style type="text/css" scoped="">
	.listDetails {
		display: flex;
		padding-left: 10px;
		align-items: center;
		position: relative;
		padding-bottom: 15px;
	}

	.listDetails:before {
		position: absolute;
		content: "";
		height: 1px;
		bottom: 5px;
		left: 40px;
		right: 20px;
		background: #ededed;
	}

	.van-checkbox {
		min-width: 24px;
	}


	
	.shopTitle .van-checkbox {
		display: inline-block;
		margin-left: 10px;
		position: relative;
		top: 4px;
	}

	
	.shopGoodsContainer {
		margin-top: 10px;
		border-radius: 6px 6px 0 0;
		background: #fff;
	}

	
	.shopTitle .van-icon-send-gift {
		color: red;
		margin: 0 3px;
		font-size: 25px;
		top: 6px;
	}

	.van-submit-bar__text{
		color: red;;
	}
	.van-submit-bar__price {
		font-weight: 600;
	}

	
</style>